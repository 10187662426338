<script setup lang="ts">
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useMarketplaceMenu } from '/~/layouts/main/components/use-marketplace-menu'

const { hideMenu, menu, activeItem } = useMarketplaceMenu()
</script>

<template>
  <div
    v-if="!hideMenu"
    class="flex shrink-0 snap-x overflow-x-auto border-b py-2.5 sm:border-0 sm:pb-5 sm:pt-8"
  >
    <div
      class="flex shrink-0 space-x-3 px-2.5 text-eonx-neutral-800 sm:space-x-4 sm:px-0"
    >
      <base-action
        v-for="item in menu"
        :key="item.id"
        v-bind="item.action"
        color=""
        class="flex h-10 snap-center items-center justify-center rounded-lg border px-3 transition-all duration-700 hover:text-primary sm:h-12 sm:min-w-40 sm:border-0"
        :class="{
          'bg-white text-eonx-neutral-800':
            activeItem && activeItem.id !== item.id,
          'bg-primary-lightest text-primary':
            activeItem && activeItem.id === item.id,
        }"
      >
        <base-icon
          v-if="item.icon"
          :size="24"
          color="none"
          class="mr-2"
          :svg="item.icon"
        />
        <div class="shrink-0 font-bold">
          {{ item.label }}
        </div>
      </base-action>
    </div>
  </div>
</template>
